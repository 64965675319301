import React from 'react'
import { graphql } from 'gatsby'
import rehypeReact from 'rehype-react'
import PropTypes from 'prop-types'
import { Container, Hero, Heading } from 'react-bulma-components'
// import styles from '../styles'
import Counter from '../components/counter'
// import Layout from '../components/layouts/blog'
import Layout from '../components/layouts/index'
import { rhythm, scale } from '../utils/typography'
import SEO from '../components/seo'

// import 'katex/dist/katex.min.css'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { 'interactive-counter': Counter }
}).Compiler

class BlogPostRoute extends React.Component {
  render() {
    const post = this.props.data.markdownRemark

    return (
      <Layout
        location={this.props.location}
        className="index"
        snipperLogo={this.props.data.snipperLogo.childImageSharp.fluid}
      >
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          titleTemplate={() =>
            `%s | ${this.props.data.site.siteMetadata.blogTitle}`
          }
          isBlogPost
          postData={post}
          url={this.props.location.href}
        />
        <Hero size="fullheight">
          <Hero.Body>
            <Container>
              <header>
                <Heading
                  size={1}
                  css={{
                    marginBottom: rhythm(1 / 6),
                    color: post.frontmatter.shadow
                  }}
                >
                  {post.frontmatter.title}
                </Heading>
                <p
                  css={{
                    ...scale(-1 / 5),
                    display: `block`
                    // color: `${styles.colors.light}`
                  }}
                >
                  {post.timeToRead} min read
                </p>
              </header>
              <Heading size={2} renderAs="h2">
                Contents
              </Heading>
              <div
                dangerouslySetInnerHTML={{ __html: post.tableOfContents }}
                className="toc"
                css={{
                  marginBottom: rhythm(1)
                }}
              />

              {renderAst(post.htmlAst)}
              <hr
                css={{
                  marginBottom: rhythm(1),
                  marginTop: rhythm(2)
                }}
              />
              <p
                css={{
                  marginBottom: rhythm(4 / 4),
                  display: `flex`,
                  alignItems: `center`
                }}
              >
                <span
                  css={{
                    // color: styles.colors.light,
                    ...scale(-1 / 5)
                  }}
                >
                  <small
                    css={{
                      fontWeight: `bold`,
                      // color: styles.colors.text,
                      textTransform: `uppercase`
                    }}
                  >
                    {post.frontmatter.author.id}
                  </small>
                  {` `}
                  {post.frontmatter.author.bio}
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<a href="https://twitter.com/ateologov?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @ateologov</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`
                    }}
                  />
                </span>
              </p>
            </Container>
          </Hero.Body>
        </Hero>
      </Layout>
    )
  }
}

BlogPostRoute.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}

export default BlogPostRoute

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        blogTitle
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 160)
      htmlAst
      timeToRead
      tableOfContents
      frontmatter {
        title
        image
        tags
        date(formatString: "MMMM DD, YYYY")
        author {
          id
          bio
        }
        description
      }
    }
    snipperLogo: file(
      absolutePath: { regex: "/snipper-logo-512×512@2x.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
